import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { apiRoutes } from '../../constants/constants';
import { NotificationService } from '../notification.service';
import { NgxUiLoaderService } from "ngx-ui-loader";

@Injectable({ providedIn: 'root' })
export class ProductoService {
    constructor(private http: HttpClient, private notificationService: NotificationService,
        private loaderService: NgxUiLoaderService) {
    }

    getData() {
        this.loaderService.start();
        return new Promise((resolve, reject) => {
            this.http.get(apiRoutes.ROUTE_PRODUCTO).subscribe(
                (response: any) => {
                    let success = this.notificationService.verifyStatus(response, false);
                    this.loaderService.stop();
                    if (success) resolve(response.data);
                    resolve(null);
                },
                (error: any) => {
                    this.notificationService.error(error);
                    this.loaderService.stop();
                    reject(error);
                });
        });
    }

    getDataById(id: any) {
        this.loaderService.start();
        return new Promise((resolve, reject) => {
            this.http.get(apiRoutes.ROUTE_PRODUCTO + "/" + id).subscribe(
                (response: any) => {
                    let success = this.notificationService.verifyStatus(response, false);
                    this.loaderService.stop();
                    if (success) resolve(response.data);
                    resolve(null);
                },
                (error: any) => {
                    this.notificationService.error(error);
                    this.loaderService.stop();
                    reject(error);
                });
        });
    }

    GetInfoById(id: any) {
        this.loaderService.start();
        return new Promise((resolve, reject) => {
            this.http.get(apiRoutes.ROUTE_PRODUCTO + "/info/" + id).subscribe(
                (response: any) => {
                    let success = this.notificationService.verifyStatus(response, false);
                    this.loaderService.stop();
                    if (success) resolve(response.data);
                    resolve(null);
                },
                (error: any) => {
                    this.notificationService.error(error);
                    this.loaderService.stop();
                    reject(error);
                });
        });
    }

    sendData(Producto: any) {
        this.loaderService.start();
        let method = Producto.ProductoId ? 'put' : 'post';
        return new Promise((resolve, reject) => {
            this.http[method]<any>(apiRoutes.ROUTE_PRODUCTO, Producto).subscribe(
                (response: any) => {
                    let success = this.notificationService.verifyStatus(response, true);
                    this.loaderService.stop();
                    resolve({ success: success, productoId: response.data && response.data.length > 0 ? response.data[0].ProductoId : null });
                },
                (error: any) => {
                    this.notificationService.error(error);
                    this.loaderService.stop();
                    reject(error);
                });
        });
    }

    createPdfPrecioProducto(Producto: any) {
        this.loaderService.start();
        let method = 'post';
        return new Promise((resolve, reject) => {
            this.http[method]<any>(apiRoutes.ROUTE_PRODUCTO_PRECIO_QR, Producto).subscribe(
                (response: any) => {
                    let success = this.notificationService.verifyStatus(response, true);
                    this.loaderService.stop();
                    if (success) resolve(response.data);
                },
                (error: any) => {
                    this.notificationService.error(error);
                    this.loaderService.stop();
                    reject(error);
                });
        });
    }

    deleteData(IdProducto: number) {
        this.loaderService.start();
        return new Promise((resolve, reject) => {
            this.http.delete(apiRoutes.ROUTE_PRODUCTO + '/' + IdProducto).subscribe(
                (response: any) => {
                    let success = this.notificationService.verifyStatus(response, true);
                    this.loaderService.stop();
                    resolve(success);
                },
                (error: any) => {
                    this.notificationService.error(error);
                    this.loaderService.stop();
                    reject(error);
                });
        });
    }

}