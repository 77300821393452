import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { apiRoutes } from '../../constants/constants';
import { NotificationService } from '../notification.service';
import { IPerfilClienteData } from '../../interfaces/interfaces';

@Injectable({ providedIn: 'root' })
export class PerfilClienteService 
{
  constructor(private http: HttpClient, private notificationService: NotificationService, private loaderService: NgxUiLoaderService) {
  }
 

  getData() 
  {
    this.loaderService.start();
    return new Promise((resolve, reject) => {
      this.http.get(apiRoutes.ROUTE_PERFIL_CLIENTE).subscribe(
      (response: any) => {
        let success = this.notificationService.verifyStatus(response, false);
        this.loaderService.stop();
        if(success) resolve(response.data);
        resolve(null);
      },
      (error: any) => {
        this.notificationService.error(error);
        this.loaderService.stop();
        reject(error);
      });
    });
  }

  getDataClientes(PerfilClienteId:Number) 
  {
    this.loaderService.start();
    return new Promise((resolve, reject) => {
      this.http.get(apiRoutes.ROUTE_PERFIL_CLIENTE+ '/' + PerfilClienteId).subscribe(
      (response: any) => {
        let success = this.notificationService.verifyStatus(response, false);
        this.loaderService.stop();
        if(success) resolve(response.data);
        resolve(null);
      },
      (error: any) => {
        this.notificationService.error(error);
        this.loaderService.stop();
        reject(error);
      });
    });
  }

  sendData(objPerfilCliente: IPerfilClienteData) 
  {
    this.loaderService.start();
    let method = objPerfilCliente.PerfilClienteId ? 'put' : 'post';
    return new Promise((resolve, reject) => {
      this.http[method](apiRoutes.ROUTE_PERFIL_CLIENTE, objPerfilCliente).subscribe(
      (response: any) => {
        let success = this.notificationService.verifyStatus(response, true);
        this.loaderService.stop();
        resolve(success);
      },
      (error: any) => {
        this.notificationService.error(error);
        this.loaderService.stop();
        reject(error);
      });
    });
  }

  deleteData(IdPefilCliente: number) 
  {
    this.loaderService.start();
    return new Promise((resolve, reject) => {
      this.http.delete(apiRoutes.ROUTE_PERFIL_CLIENTE + '/' + IdPefilCliente).subscribe(
      (response: any) => {
        let success = this.notificationService.verifyStatus(response, true);
        this.loaderService.stop();
        resolve(success);
      },
      (error: any) => {
        this.notificationService.error(error);
        this.loaderService.stop();
        reject(error);
      });
    });
  }

  deleteCliente(Idcliente: number) 
  {
    var cliente= {
        ClienteId:Idcliente 
    }
    this.loaderService.start();
    return new Promise((resolve, reject) => {
      this.http.patch(apiRoutes.ROUTE_PERFIL_CLIENTE, cliente).subscribe(
      (response: any) => {
        let success = this.notificationService.verifyStatus(response, true);
        this.loaderService.stop();
        resolve(success);
      },
      (error: any) => {
        this.notificationService.error(error);
        this.loaderService.stop();
        reject(error);
      });
    });
  }

}