export const pagination = {
    pageIndex: 1,
    pageSize: 10,
    pageSizeOptions: [5, 10, 25, 100]
}

export const arrPagination = [
    { label: "10 Registros", value: 10 }, 
    { label: "25 Registros", value: 25 }, 
    { label: "50 Registros", value: 50 }, 
    { label: "Todos", value: 0 }
];