import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpHeaders
} from '@angular/common/http';

import { Injectable } from '@angular/core';
import { AuthService } from '../services/services';
  
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const authToken = this.authService.getToken();
        let headers = new HttpHeaders({
            'authorization': 'Bearer ' + authToken
        });
        if(req.method == "POST")
            headers.append('Content-Type', 'application/x-www-form-urlencoded');
        const authRequest = req.clone({headers});
        return next.handle(authRequest);
    }
}