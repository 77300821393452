import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { apiRoutes } from '../../constants/constants';
import { NotificationService } from '../notification.service';

import { ICompraData } from 'src/app/interfaces/interfaces';

@Injectable({ providedIn: 'root' })
export class CompraService 
{
  constructor(private http: HttpClient, private notificationService: NotificationService, private loaderService: NgxUiLoaderService) {
  }

  getData() 
  {
    this.loaderService.start();
    return new Promise((resolve, reject) => {
      this.http.get(apiRoutes.ROUTE_COMPRA).subscribe(
      (response: any) => {
        let success = this.notificationService.verifyStatus(response, false);
        this.loaderService.stop();
        if(success) resolve(response.data);
        resolve(null);
      },
      (error: any) => {
        this.notificationService.error(error);
        this.loaderService.stop();
        reject(error);
      });
    });
  }

  getFilteredData()
  {
    this.loaderService.start();
    return new Promise((resolve, reject) => {
      this.http.get(apiRoutes.ROUTE_COMPRA + '/filters').subscribe(
      (response: any) => {
        let success = this.notificationService.verifyStatus(response, false);
        this.loaderService.stop();
        if(success) resolve(response.data);
        resolve(null);
      },
      (error: any) => {
        this.notificationService.error(error);
        this.loaderService.stop();
        reject(error);
      });
    });
  }

  getDetailData(Id: number) 
  {
    this.loaderService.start();
    return new Promise((resolve, reject) => {
      this.http.get(apiRoutes.ROUTE_COMPRA + '/' + Id).subscribe(
      (response: any) => {
        let success = this.notificationService.verifyStatus(response, false);
        this.loaderService.stop();
        if(success) resolve(response.data);
        resolve(null);
      },
      (error: any) => {
        this.notificationService.error(error);
        this.loaderService.stop();
        reject(error);
      });
    });
  }

  sendData(objCompra : ICompraData)
  {
    this.loaderService.start();
    return new Promise((resolve, reject) => {
      this.http.post(apiRoutes.ROUTE_COMPRA, objCompra).subscribe(
      (response: any) => {
        console.log(response);
        let success = this.notificationService.verifyStatus(response, false);
        this.loaderService.stop();
        if(success) resolve(true);
        resolve(null);
      },
      (error: any) => {
        this.notificationService.error(error);
        this.loaderService.stop();
        reject(error);
      });
    });
  }

  sendPagoData(objCompra : ICompraData)
  {
    this.loaderService.start();
    return new Promise((resolve, reject) => {
      this.http.put(apiRoutes.ROUTE_COMPRA, objCompra).subscribe(
      (response: any) => {
        console.log(response);
        let success = this.notificationService.verifyStatus(response, true);
        this.loaderService.stop();
        if(success) resolve(true);
        resolve(null);
      },
      (error: any) => {
        this.notificationService.error(error);
        this.loaderService.stop();
        reject(error);
      });
    });
  }

  sendInfoFacturaData(objCompra : ICompraData)
  {
    this.loaderService.start();
    return new Promise((resolve, reject) => {
      this.http.put(apiRoutes.ROUTE_COMPRA_FACTURA, objCompra).subscribe(
      (response: any) => {
        let success = this.notificationService.verifyStatus(response, true);
        this.loaderService.stop();
        if(success) resolve(true);
        resolve(null);
      },
      (error: any) => {
        this.notificationService.error(error);
        this.loaderService.stop();
        reject(error);
      });
    });
  }


}