export const cMetodoVenta = {
    DIRECTA: 6,
    CONSIGNACION: 11
}

export const lstMetodoVenta = [
    {
        MetodoVentaId: cMetodoVenta.DIRECTA,
        Nombre: 'Venta Directa'
    },
    {
        MetodoVentaId: cMetodoVenta.CONSIGNACION,
        Nombre: 'Venta por Consignación'
    }
]