export const configEditor = {
    toolbar: [
        [
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            { 'color': [] }, 
            { 'background': [] },
            { 'font': [] },
            { 'align': [] }
        ],
        [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' }
        ],
        ['link'],
        ['clean']
    ]
};