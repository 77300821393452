import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { apiRoutes } from '../constants/constants';
import { NotificationService } from '../services/notification.service';
import { statesElement } from 'src/app/constants/constants';
import { ILoginData, IAuthData } from '../interfaces/interfaces';

@Injectable({ providedIn: 'root' })
export class AuthService 
{
  private isAuthenticated = false;
  private authData: IAuthData = this.createObj();
  private token: string;
  private authStatusListener = new Subject<boolean>();
  private arrMenu: any;

  constructor(private http: HttpClient, private notificationService: NotificationService, private router: Router) {
  }

  getAuthData()
  {
    this.authData = JSON.parse(localStorage.getItem('authData'));
    return this.authData;
  }

  getCajaData()
  {
    return JSON.parse(localStorage.getItem('caja'));
  }

  setCajaData(cajaId: any)
  {
    localStorage.setItem('caja', cajaId);
  }

  createObj()
  {
    return { user: null, token: null, nombre: null, fotografia: null, estados: null, usuarioEstadoId: null,puntoVenta:null };
  }
  
  getToken() 
  {
    let authData = this.getAuthData();
    this.token = authData ? authData.token : null;
    return this.token;
  }

  getIsAuth() {
    return this.isAuthenticated;
  }

  getAuthStatusListener() {
    return this.authStatusListener.asObservable();
  }

  getMenu(){
    return this.arrMenu;  
  }

  getAuthenticated()
  {
    return this.getToken() != null;
  }

  login(loginForm: any) 
  {
    const loginData: ILoginData = loginForm;
    return new Promise((resolve, reject) => {
      this.http.post(apiRoutes.ROUTE_LOGIN, loginData).subscribe(
      (response: any) => {
        console.log(response);
        let success = this.notificationService.verifyStatus(response, false);
        if(success)
        {
          if(response.data.usuarioEstadoId == statesElement.ENABLED){
            this.authData = this.createObj();
            this.authData = response.data;
            this.authData.user = loginData.User;
            this.notificationService.success(`Bienvenido, ${this.authData.nombre}`);
            this.loginSuccess();
          }else if(response.data.usuarioEstadoId == statesElement.CREATED ||
                  response.data.usuarioEstadoId == statesElement.CAMBIOPASS){
            this.router.navigate(['user/reset-password'],{queryParams:{zwcc:response.data.token}});
          }else{
            this.router.navigate(['error']);
          }
          resolve(response);
        }
        resolve(null);
      },
      (error: any) => {
        this.notificationService.error(error);
        reject();
      });
    });
  }

  existEmail(email: string)
  {
    return new Promise((resolve, reject) => {
      this.http.get(apiRoutes.ROUTE_EXISTEMAIL + `?email=${email}`).subscribe(
      (response: any) => {
        resolve(response);
      },
      (error: any) => {
        this.notificationService.error(error);
        reject();
      });
    });
  }

  register(objRegister: any)
  {
    return new Promise((resolve, reject) => {
      this.http.post(apiRoutes.ROUTE_REGISTER, objRegister).subscribe(
      (response: any) => {
        let success = this.notificationService.verifyStatus(response, false);
        resolve(success);
      },
      (error: any) => {
        this.notificationService.error(error);
        reject();
      });
    });
  }

  forgotPassword(email: string)
  {
    return new Promise((resolve, reject) => {
      this.http.post(apiRoutes.ROUTE_FORGOTPASSWORD, { email: email }).subscribe(
      (response: any) => {
        let success = this.notificationService.verifyStatus(response, false);
        if(success) this.notificationService.success(`Se enviado un correo electrónico para restablecer la contraseña, con el cual sera redirigido a la página restablecer contraseña`);
        resolve(response);
      },
      (error: any) => {
        this.notificationService.error(error);
        reject();
      });
    });
  }

  verifyChangePassword(objToken: any)
  {
    return new Promise((resolve, reject) => {
      this.http.post(apiRoutes.ROUTE_VERIFYTOKEN_USER, objToken).subscribe(
      (response: any) => {
        let success = this.notificationService.verifyStatus(response, false);
        if(!success) {
          this.notificationService.error(`Este solicitud ya ha expirado!`);
          setTimeout(() => {
            this.router.navigate(['error']);
          }, 6000);
        }
        resolve(response);
      },
      (error: any) => {
        this.notificationService.error(error);
        reject();
      });
    });
  }

  resetPassword(Token: string, Password: string)
  {
    return new Promise((resolve, reject) => {
      this.http.post(apiRoutes.ROUTE_UPDATE_PASSWORD, { Token: Token, Password: Password }).subscribe(
      (response: any) => {
        let success = this.notificationService.verifyStatus(response, true);
        if(success) {
          setTimeout(() => {
            this.router.navigate(['user/login']);
          }, 2000);
        }
        resolve(response);
      },
      (error: any) => {
        this.notificationService.error(error);
        reject();
      });
    });
  }

  loginSuccess() 
  {
    if (this.authData.token)
    {
      this.isAuthenticated = true;
      this.authStatusListener.next(true);
      let puntoVenta = this.authData.puntoVenta;
      let cajaId = null;
      if(puntoVenta && puntoVenta.length > 0 && puntoVenta[0].Caja && puntoVenta[0].Caja.length > 0) 
        cajaId = puntoVenta[0].Caja[0].CajaId;
      localStorage.setItem('caja', cajaId);
      localStorage.setItem('puntoVenta',JSON.stringify(puntoVenta));
      localStorage.setItem('authData', JSON.stringify(this.authData));
      this.router.navigate(['/app']);
    }
  }

  ValidarSession() {
    return this.http.get<{ token: string; }>(
      'http://localhost:3000/login'
    );
  }

  autoAuthUser() {
    const authInformation = this.getAuthData();
    if (!authInformation) {
      return;
    }
    this.token = authInformation.token;
    this.isAuthenticated = true;
    this.authStatusListener.next(true);
  }

  logout() {
    this.token = null;
    this.isAuthenticated = false;
    this.authStatusListener.next(false);
    localStorage.removeItem('authData');
    localStorage.removeItem('studentData');
    this.router.navigate(['user/login']);
  }

  private saveAuthData(token: string) 
  {
    /*
    localStorage.setItem('token', token);
    localStorage.setItem('user', this.user);
    localStorage.setItem('fotografia', this.nameUser);
    localStorage.setItem('fotografia', this.fotografia);
    */
  }

  private clearAuthData() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('nameUser');
  }

  getMenuData() {
    let authData = this.getAuthData();
    this.arrMenu = this.createMenu(authData.menu);
    console.log(this.arrMenu);
    return this.arrMenu;
  }

  createMenu(lstMenu: any[])
  {
    let lstResult = [];
    for(let menu of lstMenu)
    {
      let objMenu = { icon: menu.icon, label: menu.nombre, to: menu.ruta };
      if(menu.hijos.length > 0) objMenu["subs"] = this.createMenu(menu.hijos);
      lstResult.push(objMenu);
    }
    return lstResult;
  }

  private clearMenuData() {
    localStorage.removeItem('menu');
  }

  private getObjToken() 
  {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }
    return {
      token: token
    };
  }
}