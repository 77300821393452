import { Component, SimpleChanges, OnChanges, Input } from '@angular/core';
import { IBreadcrumb } from '../../../interfaces/breadcrumb.interface'

@Component({
  selector: 'app-breadcrumb-manual',
  templateUrl: './breadcrumb-manual.component.html'
})
export class BreadcrumbManualComponent implements OnChanges {
  @Input() title = '';  
  @Input() breadcrumb: IBreadcrumb[] = [{name:"Home",path:"/app"}];     
  lastNameBreadcrumb: string ='';
  
  ngOnChanges(changes: SimpleChanges) {
    this.lastNameBreadcrumb = this.breadcrumb[this.breadcrumb.length-1].name;
  }
}
