import { environment } from 'src/environments/environment';
import { UserRole } from '../shared/auth.roles';
const adminRoot = environment.adminRoot;

export interface IMenuItem {
  id?: string;
  icon?: string;
  label: string;
  to: string;
  newWindow?: boolean;
  subs?: IMenuItem[];
  roles?: UserRole[];
}

const data: IMenuItem[] = [
  {
    icon: 'fas fa-cogs',
    label: 'menu.configuration',
    to: `${adminRoot}/configuracion`,
    subs: [
      {
        icon: 'fas fa-warehouse',
        label: 'menu.submenu-configuration.store',
        to: `${adminRoot}/configuracion/bodega`
      },
      {
        icon: 'fas fa-box-open',
        label: 'menu.submenu-configuration.product',
        to: `${adminRoot}/configuracion/producto`
      },
      {
        icon: 'fas fa-truck',
        label: 'menu.submenu-configuration.provider',
        to: `${adminRoot}/configuracion/proveedor`
      }
    ]
  }
];

export default data;
