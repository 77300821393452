import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Router } from '@angular/router';
import { apiRoutes } from '../../constants/constants';
import { NotificationService } from '../notification.service';
import { ITagData } from '../../interfaces/interfaces';

@Injectable({ providedIn: 'root' })
export class TagService 
{
  constructor(private http: HttpClient, private notificationService: NotificationService,private loaderService: NgxUiLoaderService) {
  }


  getData() 
  {
    this.loaderService.start();
    return new Promise((resolve, reject) => {
      this.http.get(apiRoutes.ROUTE_TAG).subscribe(
      (response: any) => {
        let success = this.notificationService.verifyStatus(response, false);
        this.loaderService.stop();
        if(success) resolve(response.data);
        resolve(null);
      },
      (error: any) => {
        this.notificationService.error(error);
        this.loaderService.stop();
        reject(error);
      });
    });
  }

  sendData(objTag: ITagData) 
  {
    this.loaderService.start();
    let method = objTag.TagId ? 'put' : 'post';
    return new Promise((resolve, reject) => {
      this.http[method](apiRoutes.ROUTE_TAG, objTag).subscribe(
      (response: any) => {
        let success = this.notificationService.verifyStatus(response, true);
        this.loaderService.stop();
        resolve(success);
      },
      (error: any) => {
        this.notificationService.error(error);
        this.loaderService.stop();
        reject(error);
      });
    });
  }

  deleteData(tagId: number) 
  {
    this.loaderService.start();
    return new Promise((resolve, reject) => {
      this.http.delete(apiRoutes.ROUTE_TAG + '/' + tagId).subscribe(
      (response: any) => {
        let success = this.notificationService.verifyStatus(response, true);
        this.loaderService.stop();
        resolve(success);
      },
      (error: any) => {
        this.notificationService.error(error);
        this.loaderService.stop();
        reject(error);
      });
    });
  }
}