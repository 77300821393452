export const typeDocuments = [
    {
        id: 151,
        nombre: "Boleta",
        blnBanco: false,
        blnNoDocOtro: false,
        blnNoDocDestino: true,
        blnCuentaDestino: true,
        blnFechaDeposito: true
    },
    {
        id: 152,
        nombre: "Cheque",
        blnBanco: true,
        blnNoDocOtro: true,
        blnNoDocDestino: false,
        blnCuentaDestino: false,
        blnFechaDeposito: false
    },
    {
        id: 154,
        nombre: "Crédito interno",
        blnBanco: false,
        blnNoDocOtro: false,
        blnNoDocDestino: false,
        blnCuentaDestino: false,
        blnFechaDeposito: false
    },
    {
        id: 155,
        nombre: "Corrección interna",
        blnBanco: false,
        blnNoDocOtro: false,
        blnNoDocDestino: false,
        blnCuentaDestino: false,
        blnFechaDeposito: false
    },
    {
        id: 156,
        nombre: "ACH",
        blnBanco: true,
        blnNoDocOtro: true,
        blnNoDocDestino: false,
        blnCuentaDestino: false,
        blnFechaDeposito: false
    },
    {
        id: 157,
        nombre: "Efectivo",
        blnBanco: false,
        blnNoDocOtro: false,
        blnNoDocDestino: false,
        blnCuentaDestino: false,
        blnFechaDeposito: false
    },
    {
        id: 158,
        nombre: "Reversion",
        blnBanco: false,
        blnNoDocOtro: false,
        blnNoDocDestino: false,
        blnCuentaDestino: false,
        blnFechaDeposito: false
    },
    {
        id: 159,
        nombre: "Descuento",
        blnBanco: false,
        blnNoDocOtro: false,
        blnNoDocDestino: false,
        blnCuentaDestino: false,
        blnFechaDeposito: false
    }
];