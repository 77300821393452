import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Router } from '@angular/router';
import { apiRoutes } from '../../constants/constants';
import { NotificationService } from '../notification.service';
import { IClienteData } from '../../interfaces/interfaces';

@Injectable({ providedIn: 'root' })
export class ClienteService{
    constructor(private http: HttpClient, private notificationService: NotificationService,private loaderService: NgxUiLoaderService) {
    }
  
    

    getData() 
    {
      this.loaderService.start();
      return new Promise((resolve, reject) => {
        this.http.get(apiRoutes.ROUTE_CLIENTE).subscribe(
        (response: any) => {
          let success = this.notificationService.verifyStatus(response, false);
          this.loaderService.stop();
          if(success) resolve(response.data);
          resolve(null);
        },
        (error: any) => {
          this.notificationService.error(error);
          this.loaderService.stop();
          reject(error);
        });
      });
    }
  
    sendData(objCliente: IClienteData) 
    {
      this.loaderService.start();
      let method = objCliente.ClienteId ? 'put' : 'post';
      return new Promise((resolve, reject) => {
        this.http[method](apiRoutes.ROUTE_CLIENTE, objCliente).subscribe(
        (response: any) => {
          let success = this.notificationService.verifyStatus(response, true);
          this.loaderService.stop();
          resolve(success);
        },
        (error: any) => {
          this.notificationService.error(error);
          this.loaderService.stop();
          reject(error);
        });
      });
    }

    sendDocuments(objDocumentos: any) 
    {
      this.loaderService.start();
      return new Promise((resolve, reject) => {
        this.http['post'](apiRoutes.ROUTE_CLIENTE_DOCS, objDocumentos).subscribe(
        (response: any) => {
          let success = this.notificationService.verifyStatus(response, true);
          this.loaderService.stop();
          resolve(success);
        },
        (error: any) => {
          this.notificationService.error(error);
          this.loaderService.stop();
          reject(error);
        });
      });
    }
  
    deleteData(ClienteId: number) 
    {
      this.loaderService.start();
      return new Promise((resolve, reject) => {
        this.http.delete(apiRoutes.ROUTE_CLIENTE + '/' + ClienteId).subscribe(
        (response: any) => {
          let success = this.notificationService.verifyStatus(response, true);
          this.loaderService.stop();
          resolve(success);
        },
        (error: any) => {
          this.notificationService.error(error);
          this.loaderService.stop();
          reject(error);
        });
      });
    }

    getPerfilData(ClienteId: number) 
    {
      this.loaderService.start();
      return new Promise((resolve, reject) => {
        this.http.get(apiRoutes.ROUTE_CLIENTE + '/' + ClienteId).subscribe(
        (response: any) => {
          let success = this.notificationService.verifyStatus(response, false);
          this.loaderService.stop();
          if(success) resolve(response.data);
          resolve(null);
        },
        (error: any) => {
          this.notificationService.error(error);
          this.loaderService.stop();
          reject(error);
        });
      });
    }
}