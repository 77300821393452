import { appConfig } from './config.constants';

export const apiRoutes = {
    ROUTE_LOGIN                  : appConfig.API_ENDPOINT + '/login',
    ROUTE_UPDATE_PASSWORD        : appConfig.API_ENDPOINT + '/login/actualizarContrasena',
    ROUTE_EXISTEMAIL             : appConfig.API_ENDPOINT + '/registroPadre/existenciaEmail',
    ROUTE_REGISTER               : appConfig.API_ENDPOINT + '/registroPadre',
    ROUTE_FORGOTPASSWORD         : appConfig.API_ENDPOINT + '/cuentaPadre/olvidoContrasena',
    ROUTE_VERIFYTOKEN_FP         : appConfig.API_ENDPOINT + '/cuentaPadre/aceptaRestablecerContrasena',
    ROUTE_BODEGA                 : appConfig.API_ENDPOINT + '/bodega',
    ROUTE_PROVEEDOR              : appConfig.API_ENDPOINT + '/proveedor',
    ROUTE_PRODUCTO               : appConfig.API_ENDPOINT + '/producto',
    ROUTE_PRODUCTO_PRECIO_QR     : appConfig.API_ENDPOINT + '/producto/pdf/precioqr',
    ROUTE_CLIENTE                : appConfig.API_ENDPOINT + '/cliente',
    ROUTE_CLIENTE_DOCS           : appConfig.API_ENDPOINT + '/cliente/files',
    ROUTE_COMPRA                 : appConfig.API_ENDPOINT + '/compra',
    ROUTE_COMPRA_FACTURA         : appConfig.API_ENDPOINT + '/compra/factura',
    ROUTE_SERVICIO               : appConfig.API_ENDPOINT + '/servicio',
    ROUTE_INVENTARIO             : appConfig.API_ENDPOINT + '/inventario',
    ROUTE_INVENTARIO_AJUSTE      : appConfig.API_ENDPOINT + '/inventario/ajuste',
    ROUTE_INVENTARIO_HISTORIAL   : appConfig.API_ENDPOINT + '/inventario/historial',
    ROUTE_VENTA                  : appConfig.API_ENDPOINT + '/venta',
    ROUTE_TAG                    : appConfig.API_ENDPOINT + '/tag',
    ROUTE_PUNTO_VENTA            : appConfig.API_ENDPOINT + '/puntoVenta',
    ROUTE_DASHBOARD              : appConfig.API_ENDPOINT + '/dashboard',
    ROUTE_REGISTRO_CAJA          : appConfig.API_ENDPOINT + '/registroCaja',
    ROUTE_CATEGORIA              : appConfig.API_ENDPOINT + '/categoria',
    ROUTE_PERFIL_USUARIO         : appConfig.API_ENDPOINT + '/perfilUsuario',
    ROUTE_PERFIL_USUARIO_LISTA   : appConfig.API_ENDPOINT + '/perfilUsuario/lista',
    ROUTE_PERFIL_USUARIO_USUARIO : appConfig.API_ENDPOINT + '/perfilUsuario/usuario',
    ROUTE_PERFIL_CLIENTE         : appConfig.API_ENDPOINT + '/perfilCliente',
    ROUTE_LISTA_PRECIO           : appConfig.API_ENDPOINT + '/listaPrecio',
    ROUTE_LISTA_PRECIO_PRODUCTO  : appConfig.API_ENDPOINT + '/listaPrecio/producto',
    ROUTE_LISTA_PRECIO_FACTOR    : appConfig.API_ENDPOINT + '/listaPrecio/factor',
    ROUTE_LISTA_PRECIO_PRODUCTOS_XLSX  : appConfig.API_ENDPOINT + '/listaPrecio/productosXslx',
    ROUTE_CAJA_HISTORIAL         : appConfig.API_ENDPOINT + '/cobro/historial',
    ROUTE_USUARIO                : appConfig.API_ENDPOINT + '/usuario',
    ROUTE_RENICIO_PASS           : appConfig.API_ENDPOINT + '/usuario/restart',
    ROUTE_CAMBIAR_PASS           : appConfig.API_ENDPOINT + '/usuario/cambiarContrasena',
    ROUTE_VERIFYTOKEN_USER       : appConfig.API_ENDPOINT + '/usuario/aceptaRestablecerContrasena',
    ROUTE_CAJA_ABONO             : appConfig.API_ENDPOINT + '/abono',
    ROUTE_CAJA_ABONO_DETALLE     : appConfig.API_ENDPOINT + '/abono/detalle',
    ROUTE_CAJA_CARGO             : appConfig.API_ENDPOINT + '/cobro',
    ROUTE_HISTORIAL_MOVIMIENTOS  : appConfig.API_ENDPOINT + '/estudiante/info/historialMovimientos',
    ROUTE_HISTORIAL_MOVIMIENTOS_PDF     : appConfig.API_ENDPOINT + '/cobro/historial',
    ROUTE_CAJA_AJUSTE_CARGO      : appConfig.API_ENDPOINT + '/ajusteCargo',
    ROUTE_CUENTA_HISTORIAL       : appConfig.API_ENDPOINT + '/cuentaContable',
    ROUTE_COBROS_MENSUAL         : appConfig.API_ENDPOINT + '/cobrosMensual',
    ROUTE_CAJA_AJUSTE_ABONO      : appConfig.API_ENDPOINT + '/ajusteAbono',
    ROUTE_REPORTE_DTE            : appConfig.API_ENDPOINT + '/factura',
    ROUTE_DTE_ANULACION          : appConfig.API_ENDPOINT + '/factura/anular',
    ROUTE_DTE_ENVIO              : appConfig.API_ENDPOINT + '/factura/send/dte',
    ROUTE_DTE_PDF                : appConfig.API_ENDPOINT + '/factura/info/pdf/{{dteId}}',
    ROUTE_NOTA_CREDITO           : appConfig.API_ENDPOINT + '/notaCredito',
    ROUTE_PAGO                   : appConfig.API_ENDPOINT + '/pago',
    ROUTE_FACTURA                : appConfig.API_ENDPOINT + '/factura',
    ROUTE_VALIDAR_NIT            : appConfig.API_ENDPOINT + '/cliente/info/nit',
    ROUTE_VALIDAR_CUI            : appConfig.API_ENDPOINT + '/cliente/info/cui',
    ROUTE_VENTA_UPD_NIT          : appConfig.API_ENDPOINT + '/venta/actualizaNit',
    ROUTE_ABONO_REPETIDO         : appConfig.API_ENDPOINT + '/abono/boleta/existente',
    ROUTE_ABONOS_PENDIENTES      : appConfig.API_ENDPOINT + '/abono/pendientes/deposito',
    ROUTE_PARTIDA                : appConfig.API_ENDPOINT + '/partida',
    ROUTE_PARTIDA_COMPRA         : appConfig.API_ENDPOINT + '/partida/compra',
    ROUTE_PARTIDA_VENTA          : appConfig.API_ENDPOINT + '/partida/venta',
    ROUTE_REPORTE_VENTA_CONTADO  : appConfig.API_ENDPOINT + '/venta/ventaContado',
    ROUTE_VENTA_CONSIGNACION     : appConfig.API_ENDPOINT + '/venta/tools/convertirConsignacion',
    ROUTE_REPORTE_INVENTARIO     : appConfig.API_ENDPOINT + '/inventario/reporte/getReporteInventario',
    ROUTE_VENTA_PENDIENTE     : appConfig.API_ENDPOINT + '/venta/tools/ventasPendientes',
    ROUTE_VENTA_CONVERTIR_PENDIENTE     : appConfig.API_ENDPOINT + '/venta/tools/convertirPendiente',
}