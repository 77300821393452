import { Injectable } from '@angular/core';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Injectable({ providedIn: 'root' })
export class PagoPDFService {
    nombreColegio: String;
    direccionColegio: String;
    telefono1: String;
    telefono2: String;
    fecha: String;
    recibimosDe: String;
    concepto: String;
    metodoPago: String;
    total: String;
    detallePago: any;
    correlativoInterno: any;

    constructor() {

    }

    generatePdf(nombreColegio: String, direccionColegio: String,
        telefono1: String, telefono2: String, fecha: string,
        recibimosDe: String, concepto: String, metodoPago: String,
        total: String, detallePago: any, correlativoInterno: any) {

        pdfMake.createPdf(this.getDefintion(nombreColegio, direccionColegio,
            telefono1, telefono2, fecha,
            recibimosDe, concepto, metodoPago,
            total, detallePago, correlativoInterno)).print();
    }

    public getDefintion(nombreColegio: String, direccionColegio: String,
        telefono1: String, telefono2: String, fecha: string,
        recibimosDe: String, concepto: String, metodoPago: String,
        total: String, detallePago: any, correlativoInterno: any) {
        this.nombreColegio = nombreColegio;
        this.direccionColegio = direccionColegio;
        this.telefono1 = telefono1;
        this.telefono2 = telefono2;
        this.fecha = fecha;
        this.recibimosDe = recibimosDe;
        this.concepto = concepto;
        this.metodoPago = metodoPago;
        this.total = total;
        this.detallePago = detallePago;
        this.correlativoInterno = correlativoInterno;
        //let date = new Date(fecha);
        //this.fecha = date.toLocaleString();
        let dd = {
            pageSize: {
                width: 200,
                height: 'auto'
            },

            pageMargins: [5, 0, 5, 0],
            styles: {
                Header: {
                    fontSize: 9,
                    bold: true,
                    alignment: 'center'
                },
                BodyCenter: {
                    fontSize: 8,
                    alignment: 'center'
                },
                BodyLeft: {
                    fontSize: 8,
                    alignment: 'left'
                },
                BodyLeftBold: {
                    fontSize: 8,
                    alignment: 'left',
                    bold: true
                },
                TableLeft: {
                    fontSize: 7,
                    alignment: 'left'
                },
                TableRight: {
                    fontSize: 7,
                    alignment: 'right'
                },
                BodyTableRight: {
                    fontSize: 7,
                    alignment: 'right'
                },
                BodyTableLeft: {
                    fontSize: 7,
                    alignment: 'left'
                },
                centertme:
                {
                    alignment: 'center'
                }

            },
            pageOrientation: 'portrait',
            content: [
                { text: '\n', style: 'BodyCenter' },

                { text: 'Comprobante de Pago #' + this.correlativoInterno, style: 'Header' },
                this.getTituloFactura("Datos del Emisor"),
                { text: this.nombreColegio, style: 'Header' },
                // { text: this.direccionColegio, style: 'BodyCenter' },
                { text: 'Tel:' + this.telefono1 + (this.telefono2 ? ' / ' + this.telefono2 : ''), style: 'BodyCenter' },
                { text: '\n', style: 'BodyCenter' },
                this.getTituloFactura("Datos del Receptor"),
                this.claveValor('Fecha: ', this.fecha),
                this.claveValor('Recibimos de: ', this.recibimosDe),
                this.claveValor('Por concepto de: ', this.concepto),
                this.claveValor('Método de pago: ', this.metodoPago),
                this.claveValor('Total: ', this.transform(this.total)),
                { text: '\n', style: 'BodyCenter' },
                this.getTituloFactura("Detalle del pago"),
                // { text: 'Autorización: D5054566-3B07-4960-A3A0-F65C39340017', style: 'BodyLeft' },
                // { text: 'Fecha Certificación: 2021-05-22T09:25:15-06', style: 'BodyLeft' },
                // { text: 'Sujeto a pagos trimestrales ISR', style: 'BodyLeft' },

                {
                    layout: this.getLayoutTabla().layout,
                    table: {
                        headerRows: 1,
                        widths: [46, 86, 46],
                        body: this.getDetallePagoEstudiantes(this.detallePago)
                    }
                },
                { text: '\n\n', style: 'BodyCenter' },
                // this.getTituloFactura("Datos del Sistema"),
                // { text: 'Plataforma: SimplyGT', style: 'BodyLeft' },
                // { text: 'Certificador: Inforum Consulting', style: 'BodyLeft' },
                // { text: 'NIT: 43430775', style: 'BodyLeft' },
                { text: '', style: 'BodyCenter' },
            ]
        }
        return dd
    }


    getLayoutTabla() {
        return {
            layout: {
                // code from lightHorizontalLines:
                hLineWidth: function (i, node) {
                    if (i === 0 || i === node.table.body.length) {
                        return 0;
                    }
                    return (i === node.table.headerRows) ? 2 : 1;
                },
                vLineWidth: function (i) {
                    return 0;
                },
                hLineColor: function (i) {
                    return i === 1 ? 'black' : '#aaa';
                },
                paddingLeft: function (i) {
                    return i === 0 ? 0 : 3;
                },
                paddingRight: function (i, node) {
                    return (i === node.table.widths.length - 1) ? 0 : 3;
                },
                // code for zebra style:
                fillColor: function (i, node) {
                    // return (i % 2 === 0) ? '#CCCCCC' : null;
                }
            }
        }
    }

    getTituloFactura(titulo: String) {
        return {
            layout: this.getLayoutTabla().layout,
            table: {
                headerRows: 1,
                widths: ['*'],
                body: [
                    [{ text: titulo, style: 'BodyCenter' }],
                    [{ text: '', style: 'BodyCenter' }],
                ]
            }
        };
    }

    getDetallePagoEstudiantes(lstEstudiante: any) {
        let BodyTable = [];
        BodyTable.push([
            { text: 'Monto', style: 'BodyLeftBold' },
            { text: 'Cliente', style: 'BodyLeftBold' },
            { text: 'Saldo', style: 'BodyLeftBold' },
        ]);
        for (const Detalle of lstEstudiante) {
            BodyTable.push([
                { text: this.transform(Detalle.Monto), style: 'BodyTableLeft' },
                { text: Detalle.Cliente.Nombre, style: 'BodyTableLeft' },
                { text: this.transform(Detalle.Saldo), style: 'BodyTableLeft' }
            ]);
        }
        console.log("ahorita viene el perro maldito");
        BodyTable.push([{ text: this.transform(this.total), style: 'TableLeft' }, '', '']);
        return BodyTable;
    }


    claveValor(clave: String, valor: String) {
        return {
            text: [
                { text: clave, style: 'BodyLeftBold' }, { text: valor, style: 'BodyLeft' }]
        };
    }

    transform(value: any): any {
        if (isNaN(value) || value == null) return value;
        return "Q" + parseFloat(value.toString().replace(",", "").replace("Q", "")).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


}
