import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Router } from '@angular/router';
import { apiRoutes } from '../../constants/constants';
import { NotificationService } from '../notification.service';

@Injectable({ providedIn: 'root' })
export class PuntoVentaService 
{

  
  constructor(private http: HttpClient, private notificationService: NotificationService,private loaderService: NgxUiLoaderService) {
  }


  getData(puntoVentaId:number) 
  {
    this.loaderService.start();
    return new Promise((resolve, reject) => {

      this.http.get(apiRoutes.ROUTE_REGISTRO_CAJA+ "/" + puntoVentaId).subscribe(
      (response: any) => {
        let success = this.notificationService.verifyStatus(response, false);
        this.loaderService.stop();
        if(success) resolve(response.data);
        resolve(null);
      },
      (error: any) => {
        this.notificationService.error(error);
        this.loaderService.stop();
        reject(error);
      });
    });
  }

  sendData(objCajaPuntoVenta: any) 
  {
    this.loaderService.start();
    let method = 'post';
    return new Promise((resolve, reject) => {
      this.http[method](apiRoutes.ROUTE_REGISTRO_CAJA, objCajaPuntoVenta).subscribe(
      (response: any) => {
        let success = this.notificationService.verifyStatus(response, true);
        this.loaderService.stop();
        resolve(success);
      },
      (error: any) => {
        this.notificationService.error(error);
        this.loaderService.stop();
        reject(error);
      });
    });
  }
}