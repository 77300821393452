import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { apiRoutes } from '../../constants/constants';
import { NotificationService } from '../notification.service';
import { IListaPrecioData } from '../../interfaces/interfaces';

@Injectable({ providedIn: 'root' })
export class ListaPrecioService 
{
    constructor(private http: HttpClient, private notificationService: NotificationService, private loaderService: NgxUiLoaderService) {
    }

    getData()
    {
        this.loaderService.start();
        return new Promise((resolve, reject) => {
            this.http.get(apiRoutes.ROUTE_LISTA_PRECIO).subscribe(
            (response: any) => {
                let success = this.notificationService.verifyStatus(response, false);
                this.loaderService.stop();
                if(success) resolve(response.data);
                resolve(null);
            },
            (error: any) => {
                this.notificationService.error(error);
                this.loaderService.stop();
                reject(error);
            });
        });
    }

    getDataProducto(ListaPrecioId: number)
    {
        this.loaderService.start();
        return new Promise((resolve, reject) => {
            this.http.get(apiRoutes.ROUTE_LISTA_PRECIO_PRODUCTO + '/' + ListaPrecioId).subscribe(
            (response: any) => {
                let success = this.notificationService.verifyStatus(response, false);
                this.loaderService.stop();
                if(success) resolve(response.data);
                resolve(null);
            },
            (error: any) => {
                this.notificationService.error(error);
                this.loaderService.stop();
                reject(error);
            });
        });
    }

    getProductosListaPrecio()
    {
        this.loaderService.start();
        return new Promise((resolve, reject) => {
            this.http.get(apiRoutes.ROUTE_LISTA_PRECIO_PRODUCTOS_XLSX).subscribe(
            (response: any) => {
                let success = this.notificationService.verifyStatus(response, false);
                this.loaderService.stop();
                if(success) resolve(response.data);
                resolve(null);
            },
            (error: any) => {
                this.notificationService.error(error);
                this.loaderService.stop();
                reject(error);
            });
        });
    }

    sendData(objListaPrecio: IListaPrecioData) 
    {
        console.log(objListaPrecio);
        this.loaderService.start();
        let method = objListaPrecio.ListaPrecioId ? 'put' : 'post';
        return new Promise((resolve, reject) => {
            this.http[method](apiRoutes.ROUTE_LISTA_PRECIO, objListaPrecio).subscribe(
            (response: any) => {
                let success = this.notificationService.verifyStatus(response, true);
                this.loaderService.stop();
                resolve(success);
            },
            (error: any) => {
                this.notificationService.error(error);
                this.loaderService.stop();
                reject(error);
            });
        });
    }

    updateListProduct(ListaPrecioId: number, ProductoListaPrecio: any[]) 
    {
        this.loaderService.start();
        return new Promise((resolve, reject) => {
            this.http.put(apiRoutes.ROUTE_LISTA_PRECIO_PRODUCTO, { ListaPrecioId: ListaPrecioId, ProductoListaPrecio: ProductoListaPrecio }).subscribe(
            (response: any) => {
                let success = this.notificationService.verifyStatus(response, true);
                this.loaderService.stop();
                resolve(success);
            },
            (error: any) => {
                this.notificationService.error(error);
                this.loaderService.stop();
                reject(error);
            });
        });
    }

    updateFactorListProduct(ListaPrecioId: number)
    {
        this.loaderService.start();
        return new Promise((resolve, reject) => {
            this.http.post(apiRoutes.ROUTE_LISTA_PRECIO_FACTOR, { ListaPrecioId: ListaPrecioId }).subscribe(
            (response: any) => {
                let success = this.notificationService.verifyStatus(response, true);
                this.loaderService.stop();
                resolve(success);
            },
            (error: any) => {
                this.notificationService.error(error);
                this.loaderService.stop();
                reject(error);
            });
        });
    }

}