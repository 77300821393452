import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { apiRoutes, statesElement } from '../../constants/constants';
import { NotificationService } from '../notification.service';
import { NgxUiLoaderService } from "ngx-ui-loader";

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable({ providedIn: 'root' })
export class PDFService {
    constructor(private http: HttpClient, private notificationService: NotificationService,
        private loaderService: NgxUiLoaderService) {
    }

    generatePdf(DataVenta: any) {
        const documentDefinition = {
            pageSize: {
                width: 225,
                height: 'auto'
            },
            pageMargins: [10, 0, 10, 0],
            styles: {
                Header: {
                    fontSize: 9,
                    bold: true,
                    alignment: 'center'
                },
                BodyCenter: {
                    fontSize: 9,
                    alignment: 'center'
                },
                BodyLeft: {
                    fontSize: 9,
                    alignment: 'left'
                },
                TableLeft: {
                    fontSize: 8,
                    alignment: 'left'
                },
                centertme:
                {
                    alignment: 'center'
                }

            },
            pageOrientation: 'portrait',
            content: [

                { text: '\n\n\n', style: 'BodyCenter' },
                { text: 'Recibo Interno', style: 'Header' },
                this.getTituloFactura("Datos del Emisor"),
                { text: DataVenta.Organizacion.Nombre, style: 'Header' },
                // { text: DataVenta.Organizacion.Nit, style: 'BodyCenter' },
                //{ text: DataVenta.Organizacion.Direccion, style: 'BodyCenter' },
                //{ text: 'Tel:' + DataVenta.Organizacion.Telefono, style: 'BodyCenter' },
                { text: 'Dirección del punto de venta: ' + DataVenta.Venta.PuntoVentaDireccion, style: 'BodyCenter' },
                { text: 'Teléfono del punto de venta: ' + DataVenta.Venta.PuntoVentaTelefono, style: 'BodyCenter' },
                { text: 'Punto de Venta: ' + DataVenta.Venta.PuntoVentaNombre, style: 'BodyCenter' },
                { text: 'Caja: ' + DataVenta.Venta.CajaNombre, style: 'BodyCenter' },
                { text: '\n', style: 'BodyCenter' },
                this.getTituloFactura("Datos del Comprador"),
                { text: 'Fecha:' + DataVenta.Venta.FechaRegistro, style: 'BodyLeft' },
                // { text: 'NIT: ' + DataVenta.Venta.Nit, style: 'BodyLeft' },
                { text: 'Nombre: ' + DataVenta.Venta.RazonSocial, style: 'BodyLeft' },
                { text: '\n', style: 'BodyCenter' },
                this.getTituloFactura("Datos del Recibo"),
                { text: 'Serie: ' + DataVenta.Venta.SerieFactura, style: 'BodyLeft' },
                { text: 'Número: ' + DataVenta.Venta.NumeroFactura, style: 'BodyLeft' },
                // { text: 'Autorización: D5054566-3B07-4960-A3A0-F65C39340017', style: 'BodyLeft' },
                // { text: 'Fecha Certificación: 2021-05-22T09:25:15-06', style: 'BodyLeft' },
                // { text: 'Sujeto a pagos trimestrales ISR', style: 'BodyLeft' },
                { text: '\n', style: 'BodyCenter' },
                this.getTituloFactura("Detalle del Recibo"),
                {
                    layout: this.getLayoutTabla().layout,
                    table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        headerRows: 1,
                        widths: [35, 70, 35, 35],

                        body: this.getDetallefactura(DataVenta)
                    }
                },
                { text: '\n\n', style: 'BodyCenter' },
                // this.getTituloFactura("Datos del Sistema"),
                // { text: 'Plataforma: SimplyGT', style: 'BodyLeft' },
                // { text: 'Certificador: Inforum Consulting', style: 'BodyLeft' },
                // { text: 'NIT: 43430775', style: 'BodyLeft' },
                // Información de crédito
                { text: '\n', style: 'BodyCenter' },
                this.getTituloFactura("Información de crédito"),
                this.getInfoCredito(DataVenta.Venta),
                { text: '\n\n\n', style: 'BodyCenter' },


            ],
        };
        // pdfMake.createPdf(documentDefinition).open();
        pdfMake.createPdf(documentDefinition).print();
    }


    getLayoutTabla() {
        return {
            layout: {
                // code from lightHorizontalLines:
                hLineWidth: function (i, node) {
                    if (i === 0 || i === node.table.body.length) {
                        return 0;
                    }
                    return (i === node.table.headerRows) ? 2 : 1;
                },
                vLineWidth: function (i) {
                    return 0;
                },
                hLineColor: function (i) {
                    return i === 1 ? 'black' : '#aaa';
                },
                paddingLeft: function (i) {
                    return i === 0 ? 0 : 3;
                },
                paddingRight: function (i, node) {
                    return (i === node.table.widths.length - 1) ? 0 : 3;
                },
                // code for zebra style:
                fillColor: function (i, node) {
                    // return (i % 2 === 0) ? '#CCCCCC' : null;
                }
            },
        }

    }

    getTituloFactura(Titulo: String) {
        return {
            layout: this.getLayoutTabla().layout,
            table: {
                headerRows: 1,
                widths: ['*'],

                body: [
                    [{ text: Titulo, style: 'BodyCenter' }],
                    [{ text: '', style: 'BodyCenter' }],
                ]
            }
        };
    }

    getDetallefactura(DataVenta: any) {
        let BodyTable = [];
        BodyTable.push([{ text: 'Cantidad', style: 'TableLeft' }, { text: 'Descripción', style: 'TableLeft' }, { text: 'Precio', style: 'TableLeft' }, { text: 'SubTotal', style: 'TableLeft' }]);
        for (const Detalle of DataVenta.DetalleVenta) {
            let Precio: number = parseFloat(Detalle.PrecioUnitario) * (1 - parseFloat(Detalle.Descuento) / 100);
            console.log(Detalle);
            BodyTable.push([{ text: Detalle.Cantidad, style: 'TableLeft' }, { text: Detalle.ProductoNombre, style: 'TableLeft' },
            { text: Precio.toFixed(2), style: 'TableLeft' }, { text: Detalle.TotalVenta, style: 'TableLeft' }]);
        }
        BodyTable.push(['', '', { text: 'Total', style: 'TableLeft' }, { text: DataVenta.Venta.TotalVenta, style: 'TableLeft' }]);

        return BodyTable;
    }

    getBase64(event) {
        let me = this;
        let file = event;
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            //me.modelvalue = reader.result;
            console.log(reader.result);
            return reader.result;
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    getInfoCredito(Venta) {
        if (Venta.VentaCredito === statesElement.ENABLED) {
            return { text: 'Venta al crédito. Fecha Vec: ' + Venta.FechaFinCredito, style: 'BodyLeft' }
        } else {
            return { text: 'N/A', style: 'BodyLeft' }
        }

    }

    getBase64ImageFromUrl(url: string) {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.onload = function () {
                const reader = new FileReader();
                reader.onloadend = function () {
                    resolve(reader.result);
                }
                reader.readAsDataURL(xhr.response);
            };
            xhr.open('GET', url);
            xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
            xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
            xhr.responseType = 'blob';
            xhr.send();
        });
    }

}