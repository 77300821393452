export const tipoClienteNIT = {
    EMPRESA: {
        code: 176,
        name: 'empresa'
    },
    INDIVIDUAL: {
        code: 175,
        name: 'individual'
    }
}

export const lstTipoCliente = [
    {
        tipoClienteId: tipoClienteNIT.EMPRESA.code,
        nombre: 'Empresa'
    },
    {
        tipoClienteId: tipoClienteNIT.INDIVIDUAL.code,
        nombre: 'Individual'
    }
]