<nav class="navbar fixed-top">
  <div class="d-flex align-items-center navbar-left">
    <a (click)="menuButtonClick($event, sidebar.menuClickCount, sidebar.containerClassnames); false;"
      href="javascript:;" class="menu-button d-none d-md-block">
      <svg class="main" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 17">
        <rect x="0.48" y="0.5" width="7" height="1" />
        <rect x="0.48" y="7.5" width="7" height="1" />
        <rect x="0.48" y="15.5" width="7" height="1" />
      </svg> {{" "}}
      <svg class="sub" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 17">
        <rect x="1.56" y="0.5" width="16" height="1" />
        <rect x="1.56" y="7.5" width="16" height="1" />
        <rect x="1.56" y="15.5" width="16" height="1" />
      </svg>
    </a>

    <a (click)="mobileMenuButtonClick($event, sidebar.containerClassnames)" href="javascript:;"
      class="menu-button-mobile d-xs-block d-sm-block d-md-none">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 17">
        <rect x="0.5" y="0.5" width="25" height="1" />
        <rect x="0.5" y="7.5" width="25" height="1" />
        <rect x="0.5" y="15.5" width="25" height="1" />
      </svg>
    </a>

    <!--
    <div class="search" (click)="searchAreaClick($event)">
      <input placeholder="Search..." [(ngModel)]="searchKey" (keyup)="searchKeyUp($event)">
      <span class="search-icon" (click)="searchClick($event)">
        <i class="simple-icon-magnifier"></i>
      </span>
    </div>
    <div class="btn-group ml-2" dropdown *ngIf="!isSingleLang">
      <button dropdownToggle type="button" class="language-button dropdown-toggle btn btn-light btn-sm">
        {{currentLanguage | uppercase}} <span class="caret"></span>
      </button>
      <ul *dropdownMenu class="dropdown-menu" role="menu">
        <li role="menuitem" *ngFor="let item of languages" (click)="onLanguageChange(item)"><a class="dropdown-item"
            href="javascript:;">{{item.label}}</a></li>
      </ul>
    </div>
    -->
  </div>

  <a class="navbar-logo" [routerLink]="adminRoot" >
    <span class="logo d-none d-xs-block"></span>
    <span class="logo-mobile d-block d-xs-none"></span>
  </a>

  <div class="navbar-right">
    <div class="header-icons d-inline-block align-middle">

      <div class="position-relative d-none d-sm-inline-block" *ngIf="lstPuntoVenta && lstPuntoVenta.length > 0">
        <button type="button" class="header-icon btn btn-empty" (click)="openModal()">
          <i class="fas fa-cash-register"></i> &nbsp; {{ strNombrePuntoVenta + ' - ' + strNombreCaja }}
        </button>
      </div>
      
      <div class="d-none d-md-inline-block align-text-bottom mr-1">
        <div class="custom-control custom-switch" tooltip="Dark Mode" placement="left">
          <input type="checkbox" class="custom-control-input" id="darkModeSwitch" [checked]="isDarkModeActive"
            (change)="onDarkModeChange($event)">
          <label class="custom-control-label" for="darkModeSwitch"></label>
        </div>
      </div>

      <button class="header-icon btn btn-empty d-none d-sm-inline-block" type="button" id="fullScreenButton"
        (click)="fullScreenClick()">
        <i *ngIf="!isFullScreen" class="simple-icon-size-fullscreen"></i>
        <i *ngIf="isFullScreen" class="simple-icon-size-actual"></i>
      </button>

    </div>

    <div class="user d-inline-block" dropdown>
      <button class="btn btn-empty p-0" type="button" dropdownToggle>
        <span class="name">{{displayName}}</span>
        <span>
          <img alt="Profile Picture" src="/assets/img/profiles/responsible.png" />
        </span>
      </button>

      <div class="dropdown-menu dropdown-menu-right mt-3" *dropdownMenu role="menu">
        <a class="dropdown-item c-pointer" (click)="openModal()">Cambiar Punto de Venta</a>
        <a class="dropdown-item c-pointer" (click)="onChangePassword()">Cambiar Contraseña</a>
        <a class="dropdown-item c-pointer" (click)="onSignOut()">Cerrar Sesión</a>
      </div>
    </div>

  </div>
</nav>

<!-- PUNTO DE VENTA MODAL -->
<ng-template #puntoVentaModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Puntos de Venta</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xlg-4" *ngFor="let puntoVenta of lstPuntoVenta">
        <img *ngIf="puntoVenta && puntoVenta.Caja.length > 0" class="social-header card-img-top" style="height: 30%" src="/assets/img/scholae/wallpaper.png" />
        <div class="card mb-4" *ngIf="puntoVenta && puntoVenta.Caja.length > 0" style="border-top-left-radius: initial; border-top-right-radius: initial; height: 60%;">
          <img *ngIf="puntoVenta" alt="Profile" src="/assets/img/simply/punto-venta.png" class="img-thumbnail card-img social-profile-img c-pointer">
          <div class="card-body">
            <div class="text-center pt-4">
              <p class="list-item-heading pt-2">{{ puntoVenta.Nombre }}</p>
              <label class="radio" *ngFor="let caja of puntoVenta.Caja">
                <input type="radio" name="{{ 'PuntoVentaCaja_' + caja.CajaId }}" [value]="caja.CajaId" [(ngModel)]="intCajaId">
                <span style="display: inline;">{{ caja.CajaNombre }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modalRef.hide()">Cancelar</button>
    <button type="button" class="btn btn-primary" (click)="savePuntoVenta(); modalRef.hide()">Aplicar</button>
  </div>
</ng-template>