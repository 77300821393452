import { Injectable } from '@angular/core';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Injectable({ providedIn: 'root' })
export class ReciboPDFService {
    nombreColegio: String;
    direccionColegio: String;
    telefono1: String;
    telefono2: String;
    fecha: String;
    fechaEmision: String;
    total: String;
    totalIVA: String;
    correlativoInterno: any;
    frases: any;
    nitEmisor: any;
    autorizacion: any;
    serie: String;
    numeroDTE: String;
    nitReceptor: String;
    razonSocial: String;
    estudiantes: String;
    detalleVenta: any;
    totalSinFormato: String;
    nitCertificador: String;
    nombreCertificador: String;
    nombreDTE: String;
    nombreEmisor: String;
    tipoIdentificacion: any;
    codigoEstablecimiento: any;

    urlSATVerificador = "https://felpub.c.sat.gob.gt/verificador-web/publico/vistas/verificacionDte.jsf?tipo=autorizacion&numero=";
    constructor() {

    }

    generatePdf(nombreColegio: String, direccionColegio: String,
        telefono1: String, telefono2: String, fecha: string, fechaEmision: string,
        total: String, totalIVA: String, correlativoInterno: any, frases: any,
        nitEmisor: any, autorizacion: any, serie: String, numeroDTE: String,
        nitReceptor: String, razonSocial: String, estudiantes: String,
        detalleVenta: any, totalSinFormato: String, nitCertificador: String,
        nombreCertificador: String, nombreDTE: String, nombreEmisor: String, tipoIdentificacion: any,
        codigoEstablecimiento: String) {

        pdfMake.createPdf(this.getDefintion(nombreColegio, direccionColegio,
            telefono1, telefono2, fecha, fechaEmision,
            total, totalIVA, correlativoInterno, frases, nitEmisor,
            autorizacion, serie, numeroDTE, nitReceptor, razonSocial, estudiantes,
            detalleVenta, totalSinFormato, nitCertificador, nombreCertificador, nombreDTE, nombreEmisor,tipoIdentificacion,
            codigoEstablecimiento)).print();
    }

    public getDefintion(nombreColegio: String, direccionColegio: String,
        telefono1: String, telefono2: String, fecha: string, fechaEmision: string,
        total: String, totalIVA: String, correlativoInterno: any,
        frases: any, nitEmisor: any, autorizacion: any,
        serie: String, numeroDTE: String, nitReceptor: String,
        razonSocial: String, estudiantes: String, detalleVenta: any,
        totalSinFormato: String, nitCertificador: String,
        nombreCertificador: String, nombreDTE: String, nombreEmisor: String, tipoIdentificacion: any,
        codigoEstablecimiento: String) {
        this.nombreColegio = nombreColegio;
        this.direccionColegio = direccionColegio;
        this.telefono1 = telefono1;
        this.telefono2 = telefono2;
        this.total = total;
        this.totalIVA = totalIVA;
        this.correlativoInterno = correlativoInterno;
        this.frases = frases;
        this.nitEmisor = nitEmisor;
        this.autorizacion = autorizacion;
        this.serie = serie;
        this.numeroDTE = numeroDTE;
        this.nitReceptor = nitReceptor;
        this.razonSocial = razonSocial;
        this.estudiantes = estudiantes;
        this.detalleVenta = detalleVenta;
        this.totalSinFormato = totalSinFormato;
        this.nitCertificador = nitCertificador;
        this.nombreCertificador = nombreCertificador;
        this.nombreDTE = nombreDTE;
        this.nombreEmisor = nombreEmisor;
        this.tipoIdentificacion = tipoIdentificacion;
        this.codigoEstablecimiento = codigoEstablecimiento;
        
        this.fecha = fecha;
        this.fechaEmision = fechaEmision;
        var dd = {
            pageSize: {
                width: 200,
                height: 'auto'
            },

            pageMargins: [5, 0, 5, 0],
            styles: {
                Header: {
                    fontSize: 9,
                    bold: true,
                    alignment: 'center'
                },
                BodyCenter: {
                    fontSize: 8,
                    alignment: 'center'
                },
                BodyLeft: {
                    fontSize: 8,
                    alignment: 'left'
                },
                BodyRightBold: {
                    fontSize: 8,
                    alignment: 'left',
                    bold: true
                },
                BodyLeftBold: {
                    fontSize: 8,
                    alignment: 'left',
                    bold: true
                },
                TableLeft: {
                    fontSize: 7,
                    alignment: 'left'
                },
                TableRight: {
                    fontSize: 7,
                    alignment: 'right'
                },
                BodyTableRight: {
                    fontSize: 7,
                    alignment: 'right'
                },
                BodyTableLeft: {
                    fontSize: 7,
                    alignment: 'left'
                },
                centertme:
                {
                    alignment: 'center'
                }

            },
            pageOrientation: 'portrait',
            content: [
                { text: '\n', style: 'BodyCenter' },

                { text: this.nombreDTE, style: 'Header' },
                { text: "Régimen FEL", style: 'Header' },
                { text: "Documento Tributario Electrónico", style: 'Header' },
                this.getTituloFactura("Datos del Emisor"),
                { text: this.nombreColegio, style: 'Header' },
                { text: "NIT " + this.nitEmisor, style: 'BodyCenter' },
                { text:  this.nombreEmisor, style: 'BodyCenter' },
                { text:  "Establecimiento: " + this.codigoEstablecimiento, style: 'BodyCenter' },
                { text: this.direccionColegio, style: 'BodyCenter' },
                { text: 'Tel:' + this.telefono1 + (this.telefono2 ? ' / ' + this.telefono2 : ''), style: 'BodyCenter' },
                { text: 'Emisión:' + this.fechaEmision, style: 'BodyCenter' },
                this.generarFrases(),
                { text: '\n', style: 'BodyCenter' },
                this.getTituloFactura("Datos de Certificación"),
                this.claveValor('Fecha: ', this.fecha),
                // ,'yyyy-MM-dd HH:mm:ss', 'UTC')
                this.claveValor('Autorización No: ', ''),
                this.claveValor('', this.autorizacion),
                this.claveValor('Serie: ', this.serie),
                this.claveValor('Número de DTE: ', this.numeroDTE),
                this.claveValor(this.tipoIdentificacion == 1 ?'NIT: ' : 'CUI: ' , this.nitReceptor),
                this.claveValor('Nombre: ', this.razonSocial),
                { text: '\n', style: 'BodyCenter' },
                this.getTituloFactura("Detalle"),
                // { text: 'Autorización: D5054566-3B07-4960-A3A0-F65C39340017', style: 'BodyLeft' },
                // { text: 'Fecha Certificación: 2021-05-22T09:25:15-06', style: 'BodyLeft' },
                // { text: 'Sujeto a pagos trimestrales ISR', style: 'BodyLeft' },

                {
                    layout: this.getLayoutTabla().layout,
                    table: {
                        headerRows: 1,
                        widths: [15, 40, 36, 36, 39],
                        body: this.getDetallefactura()
                    }
                },
                this.claveValor('Total IVA: ', this.transform(this.totalIVA)),
                { text: '\n\n', style: 'BodyCenter' },
                this.getTituloFactura("Datos del Certificador"),
                this.claveValorP('NIT: ', this.nitCertificador),
                this.claveValorP('Nombre: ', this.nombreCertificador),
                this.claveValorP('Plataforma: ', "Simply"),
                { text: '\n\n', style: 'BodyCenter' },
                {
                    qr: this.urlSATVerificador
                        + this.autorizacion + '&emisor=' +
                        this.nitEmisor + '&receptor=' +
                        this.nitReceptor +
                        '&monto=' + this.totalSinFormato, alignment: "center", fit: '100'
                },
                { text: '\n\n', style: 'BodyCenter' },
            ]
        }
        return dd
    }


    getLayoutTabla() {
        return {
            layout: {
                // code from lightHorizontalLines:
                hLineWidth: function (i, node) {
                    if (i === 0 || i === node.table.body.length) {
                        return 0;
                    }
                    return (i === node.table.headerRows) ? 2 : 1;
                },
                vLineWidth: function (i) {
                    return 0;
                },
                hLineColor: function (i) {
                    return i === 1 ? 'black' : '#aaa';
                },
                paddingLeft: function (i) {
                    return i === 0 ? 0 : 3;
                },
                paddingRight: function (i, node) {
                    return (i === node.table.widths.length - 1) ? 0 : 3;
                },
                // code for zebra style:
                fillColor: function (i, node) {
                    // return (i % 2 === 0) ? '#CCCCCC' : null;
                }
            }
        }
    }

    getTituloFactura(titulo: String) {
        return {
            layout: this.getLayoutTabla().layout,
            table: {
                headerRows: 1,
                widths: ['*'],
                body: [
                    [{ text: titulo, style: 'BodyCenter' }],
                    [{ text: '', style: 'BodyCenter' }],
                ]
            }
        };
    }

    getDetallefactura() {
        let BodyTable = [];
        BodyTable.push([
            { text: 'Cant', style: 'TableLeft' },
            { text: 'Descripción', style: 'TableLeft' },
            { text: 'Precio', style: 'TableLeft' },
            { text: 'Descuento', style: 'TableLeft' },
            { text: 'Total', style: 'TableLeft' }
        ]);
        for (const Detalle of this.detalleVenta) {
            BodyTable.push([
                { text: Detalle.Cantidad, style: 'BodyTableLeft' },
                { text: Detalle.Producto.Nombre, style: 'BodyTableLeft' },
                { text: this.transform(Detalle.PrecioUnitario), style: 'BodyTableRight' },
                { text: this.transform(Detalle.DescuentoReal), style: 'BodyTableRight' },
                { text: this.transform(Detalle.Subtotal), style: 'BodyTableRight' }
            ]);
        }
        BodyTable.push(['', '', '', { text: 'Total', style: 'TableRight' }, { text: this.transform(this.total), style: 'TableLeft' }]);
        return BodyTable;
    }


    claveValor(clave: String, valor: String) {
        return {
            text: [
                { text: clave, style: 'BodyLeftBold' }, { text: valor, style: 'BodyLeft' }]
        };
    }

    claveValorP(clave: String, valor: String) {
        return {
            text: [
                {
                    text: clave, fontSize: 7,
                    alignment: 'left'
                }, {
                    text: valor, fontSize: 7,
                    alignment: 'left'
                }]
        };
    }

    generarFrases() {
        let retornar = { text: [] }
        for (const f of this.frases) {
            retornar.text.push({ text: f.Leyenda + "\n", style: 'BodyCenter' })
        }
        return retornar;
    }

    transform(value: any): any {
        if (isNaN(value) || value == null) return value;
        return "Q" + parseFloat(value.toString().replace(",", "").replace("Q", "")).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }



}
