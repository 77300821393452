import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { apiRoutes } from '../../constants/constants';
import { NotificationService } from '../notification.service';
import { IPerfilUsuarioData, IListaPrecioPerfilData, IUsuarioData } from '../../interfaces/interfaces';

@Injectable({ providedIn: 'root' })
export class PerfilUsuarioService 
{
  constructor(private http: HttpClient, private notificationService: NotificationService, private loaderService: NgxUiLoaderService) {
  }

  getData() 
  {
    this.loaderService.start();
    return new Promise((resolve, reject) => {
      this.http.get(apiRoutes.ROUTE_PERFIL_USUARIO).subscribe(
      (response: any) => {
        let success = this.notificationService.verifyStatus(response, false);
        this.loaderService.stop();
        if(success) resolve(response.data);
        resolve(null);
      },
      (error: any) => {
        this.notificationService.error(error);
        this.loaderService.stop();
        reject(error);
      });
    });
  }

  getDataListaPrecio(intPerfilUsuarioId: number) 
  {
    this.loaderService.start();
    return new Promise((resolve, reject) => {
      this.http.get(apiRoutes.ROUTE_PERFIL_USUARIO_LISTA + '/' + intPerfilUsuarioId).subscribe(
      (response: any) => {
        let success = this.notificationService.verifyStatus(response, false);
        this.loaderService.stop();
        if(success) resolve(response.data);
        resolve(null);
      },
      (error: any) => {
        this.notificationService.error(error);
        this.loaderService.stop();
        reject(error);
      });
    });
  }

  sendData(objPerfilUsuario: IPerfilUsuarioData) 
  {
    this.loaderService.start();
    let method = objPerfilUsuario.PerfilUsuarioId ? 'put' : 'post';
    return new Promise((resolve, reject) => {
      this.http[method](apiRoutes.ROUTE_PERFIL_USUARIO, objPerfilUsuario).subscribe(
      (response: any) => {
        let success = this.notificationService.verifyStatus(response, true);
        this.loaderService.stop();
        resolve(success);
      },
      (error: any) => {
        this.notificationService.error(error);
        this.loaderService.stop();
        reject(error);
      });
    });
  }

  deleteData(IdPerfilUsuario: number) 
  {
    this.loaderService.start();
    return new Promise((resolve, reject) => {
      this.http.delete(apiRoutes.ROUTE_PERFIL_USUARIO + '/' + IdPerfilUsuario).subscribe(
      (response: any) => {
        let success = this.notificationService.verifyStatus(response, true);
        this.loaderService.stop();
        resolve(success);
      },
      (error: any) => {
        this.notificationService.error(error);
        this.loaderService.stop();
        reject(error);
      });
    });
  }

  updateListaPrecios(lstListaPrecio: IListaPrecioPerfilData[])
  {
    this.loaderService.start();
    return new Promise((resolve, reject) => {
      this.http.put(apiRoutes.ROUTE_PERFIL_USUARIO_LISTA, { ListaPrecioPerfilUsuario : lstListaPrecio}).subscribe(
      (response: any) => {
        let success = this.notificationService.verifyStatus(response, true);
        this.loaderService.stop();
        resolve(success);
      },
      (error: any) => {
        this.notificationService.error(error);
        this.loaderService.stop();
        reject(error);
      });
    });
  }

  updateListaUsuarios(lstListaUsuarios: IUsuarioData[])
  {
    this.loaderService.start();
    return new Promise((resolve, reject) => {
      this.http.put(apiRoutes.ROUTE_PERFIL_USUARIO_USUARIO, { UsuarioPerfil: lstListaUsuarios }).subscribe(
      (response: any) => {
        let success = this.notificationService.verifyStatus(response, true);
        this.loaderService.stop();
        resolve(success);
      },
      (error: any) => {
        this.notificationService.error(error);
        this.loaderService.stop();
        reject(error);
      });
    });
  }

}