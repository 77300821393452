import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { apiRoutes } from '../../constants/constants';
import { NotificationService } from '../notification.service';

@Injectable({ providedIn: 'root' })
export class CuentaService 
{
  constructor(private http: HttpClient, private notificationService: NotificationService, private loaderService: NgxUiLoaderService) {
  }

  getData(strPath:String , cuentaCorrienteOrganizacionId : number, cuentaId?: number, fInicio?: String, fFin?: String):Promise<any> 
  {
    switch(strPath){
      case "historial":{
        return this.getDataHistorial(cuentaCorrienteOrganizacionId, cuentaId, fInicio, fFin);
      }
      case "abono":{
        return this.getDataAbonos(cuentaCorrienteOrganizacionId, cuentaId, fInicio, fFin);
      }
      case "cargo":{
        return this.getDataCargos(cuentaCorrienteOrganizacionId, cuentaId, fInicio, fFin);
      }
      case "pdfHistorial":{
        return this.getPDF(cuentaCorrienteOrganizacionId, cuentaId, fInicio, fFin);
      }
    }
  }

  getDataHistorial(cuentaCorrienteOrganizacionId: number, cuentaId : number, fInicio?: String, fFin?: String):Promise<any>  
  {
    this.loaderService.start();
    const url = apiRoutes.ROUTE_CUENTA_HISTORIAL;
    let params: any = {};
    if(cuentaCorrienteOrganizacionId) params['CuentaCorrienteOrganizacionId'] = cuentaCorrienteOrganizacionId;
    if(cuentaId) params['cuentaContableId'] = cuentaId;
    if(fInicio) params['FInicio'] = fInicio;
    if(fFin) params['FFin'] = fFin;
    return new Promise((resolve, reject) => {
      this.http.get(url, { params }).subscribe(
      (response: any) => {
        let success = this.notificationService.verifyStatus(response, false);
        this.loaderService.stop();
        if(success) resolve(response.data);
        resolve(null);
      },
      (error: any) => {
        this.notificationService.error(error);
        this.loaderService.stop();
        reject(error);
      });
    });
  }

  getDataAbonos(estudianteId: number, cuentaId : number, fInicio, fFin):Promise<any>  
  {
    this.loaderService.start();
    let urlCompleta = apiRoutes.ROUTE_CAJA_ABONO + '?estudianteId=' + estudianteId;
    urlCompleta += cuentaId ? '&cuentaCorrienteColegioId='+ cuentaId : '';
    urlCompleta += fInicio ? '&fInicio='+ fInicio : '';
    urlCompleta += fFin ? '&fFin='+ fFin : '';
    return new Promise((resolve, reject) => {
      this.http.get(urlCompleta).subscribe(
      (response: any) => {
        let success = this.notificationService.verifyStatus(response, false);
        this.loaderService.stop();
        if(success) resolve(response.data);
        resolve(null);
      },
      (error: any) => {
        this.notificationService.error(error);
        this.loaderService.stop();
        reject(error);
      });
    });
  }

  getDataCargos(estudianteId: number, cuentaId : number, fInicio, fFin):Promise<any>  
  {
    this.loaderService.start();
    let urlCompleta = apiRoutes.ROUTE_CAJA_CARGO + '?estudianteId=' + estudianteId;
    urlCompleta += cuentaId ? '&cuentaCorrienteColegioId='+ cuentaId : '';
    urlCompleta += fInicio ? '&fInicio='+ fInicio : '';
    urlCompleta += fFin ? '&fFin='+ fFin : '';
    return new Promise((resolve, reject) => {
      this.http.get(urlCompleta).subscribe(
      (response: any) => {
        let success = this.notificationService.verifyStatus(response, false);
        this.loaderService.stop();
        if(success) resolve(response.data);
        resolve(null);
      },
      (error: any) => {
        this.notificationService.error(error);
        this.loaderService.stop();
        reject(error);
      });
    });
  }

  getDetailAbono(Id: number) 
  {
    this.loaderService.start();
    return new Promise((resolve, reject) => {
      this.http.get(apiRoutes.ROUTE_CAJA_ABONO_DETALLE + '/' + Id).subscribe(
      (response: any) => {
        let success = this.notificationService.verifyStatus(response, false);
        this.loaderService.stop();
        if(success) resolve(response.data);
        resolve(null);
      },
      (error: any) => {
        this.notificationService.error(error);
        this.loaderService.stop();
        reject(error);
      });
    });
  }
  
  sendDataAbono(objCobro : any)
  {
    this.loaderService.start();
    return new Promise((resolve, reject) => {
      this.http.post(apiRoutes.ROUTE_CAJA_ABONO, objCobro).subscribe(
      (response: any) => {
        let success = this.notificationService.verifyStatus(response, false);
        this.loaderService.stop();
        if(success) resolve(success);
        resolve(null);
      },
      (error: any) => {
        this.notificationService.error(error);
        this.loaderService.stop();
        reject(error);
      });
    });
  }

  sendDataCobro(objCobro : any)
  {
    this.loaderService.start();
    console.log("objCobro", objCobro);
    return new Promise((resolve, reject) => {
      this.http.post(apiRoutes.ROUTE_CAJA_CARGO, objCobro).subscribe(
      (response: any) => {
        let success = this.notificationService.verifyStatus(response, false);
        this.loaderService.stop();
        if(success) resolve(success);
        resolve(null);
      },
      (error: any) => {
        this.notificationService.error(error);
        this.loaderService.stop();
        reject(error);
      });
    });
  }

  getPDF(clienteId: number, cuentaId : number, fInicio?: String, fFin?: String) 
  {
    this.loaderService.start();
    const url = apiRoutes.ROUTE_HISTORIAL_MOVIMIENTOS_PDF + '/' + clienteId + '/pdf';
    let params: any = {};
    if(fInicio) params['fInicio'] = fInicio;
    if(fFin) params['fFin'] = fFin;
    return new Promise((resolve, reject) => {
      this.http.get(url, { params }).subscribe(
      (response: any) => {
        let success = this.notificationService.verifyStatus(response, false);
        this.loaderService.stop();
        if(success) resolve(response.data);
        resolve(null);
      },
      (error: any) => {
        this.notificationService.error(error);
        this.loaderService.stop();
        reject(error);
      });
    });
  }
}