export const tipoDocumento = [
    {
        id: 151,
        nombre: "Boleta",
        blnBanco: false,
        blnNoDocOtro: false,
        blnNoDocDestino: true,
        blnCuentaDestino: true,
        blnFechaDeposito: true
    },
    {
        id: 152,
        nombre: "Cheque",
        blnBanco: true,
        blnNoDocOtro: true,
        blnNoDocDestino: false,
        blnCuentaDestino: false,
        blnFechaDeposito: false
    },
    {
        id: 154,
        nombre: "Crédito interno",
        blnBanco: false,
        blnNoDocOtro: false,
        blnNoDocDestino: false,
        blnCuentaDestino: false,
        blnFechaDeposito: false
    },
    {
        id: 155,
        nombre: "Corrección interna",
        blnBanco: false,
        blnNoDocOtro: false,
        blnNoDocDestino: false,
        blnCuentaDestino: false,
        blnFechaDeposito: false
    },
    {
        id: 156,
        nombre: "ACH",
        blnBanco: true,
        blnNoDocOtro: true,
        blnNoDocDestino: false,
        blnCuentaDestino: false,
        blnFechaDeposito: false
    },
    {
        id: 157,
        nombre: "Efectivo",
        blnBanco: false,
        blnNoDocOtro: false,
        blnNoDocDestino: false,
        blnCuentaDestino: false,
        blnFechaDeposito: false
    },
    {
        id: 158,
        nombre: "Reversion",
        blnBanco: false,
        blnNoDocOtro: false,
        blnNoDocDestino: false,
        blnCuentaDestino: false,
        blnFechaDeposito: false
    },
    {
        id: 159,
        nombre: "Descuento",
        blnBanco: false,
        blnNoDocOtro: false,
        blnNoDocDestino: false,
        blnCuentaDestino: false,
        blnFechaDeposito: false
    }
];

export const tipoDocumentoCobro = [
    {
        id: 153,
        nombre: "Manual",
        blnServicio: true
    },
    {
        id: 161,
        nombre: "Cargo Automático",
        blnServicio: false
    },
    {
        id: 162,
        nombre: "Por Corrección",
        blnServicio: false
    }
];

export const tipoDocumentoPago = [
    {
        id: 300,
        nombre: "Efectivo",
        bancoOrigen: {
            visible: false,
            required: false
        },
        documentoOrigen: {
            visible: false,
            required: false
        },
        cuentaDestino: {
            visible: true,
            required: false
        },
        noBoleta: {
            visible: true,
            required: false
        },
        fechaDeposito: {
            visible: true,
            required: false
        },
        POS: {
            visible: false,
            required: false
        },
        blnToggle: true
    },
    {
        id: 301,
        nombre: "Tarjeta",
        bancoOrigen: {
            visible: false,
            required: false
        },
        documentoOrigen: {
            visible: true,
            required: true
        },
        cuentaDestino: {
            visible: false,
            required: false
        },
        noBoleta: {
            visible: false,
            required: false
        },
        fechaDeposito: {
            visible: false,
            required: false
        },
        POS: {
            visible: true,
            required: true
        },
        blnToggle: false
    },
    {
        id: 302,
        nombre: "Cheque",
        bancoOrigen: {
            visible: true,
            required: true
        },
        documentoOrigen: {
            visible: true,
            required: true
        },
        cuentaDestino: {
            visible: true,
            required: false
        },
        noBoleta: {
            visible: true,
            required: false
        },
        fechaDeposito: {
            visible: true,
            required: false
        },
        POS: {
            visible: false,
            required: false
        },
        blnToggle: true
    },
    {
        id: 303,
        nombre: "Transferencia",
        bancoOrigen: {
            visible: true,
            required: true
        },
        documentoOrigen: {
            visible: true,
            required: true
        },
        cuentaDestino: {
            visible: true,
            required: false
        },
        noBoleta: {
            visible: true,
            required: false
        },
        fechaDeposito: {
            visible: true,
            required: false
        },
        POS: {
            visible: false,
            required: false
        },
        blnToggle: true
    },
    {
        id: 304,
        nombre: "Boleta",
        bancoOrigen: {
            visible: false,
            required: false
        },
        documentoOrigen: {
            visible: false,
            required: false
        },
        cuentaDestino: {
            visible: true,
            required: true
        },
        noBoleta: {
            visible: true,
            required: true
        },
        fechaDeposito: {
            visible: true,
            required: true
        },
        POS: {
            visible: false,
            required: false
        },
        blnToggle: false
    }
];