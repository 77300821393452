import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { apiRoutes } from '../../constants/constants';
import { NotificationService } from '../notification.service';
import { IUserData, IPasswordData } from '../../interfaces/interfaces';

@Injectable({ providedIn: 'root' })
export class UsuarioService
{
  constructor(private http: HttpClient, private notificationService: NotificationService,private loaderService: NgxUiLoaderService) {
  }

  getData() 
  {
    this.loaderService.start();
    return new Promise((resolve, reject) => {
      this.http.get(apiRoutes.ROUTE_USUARIO).subscribe(
      (response: any) => {
        let success = this.notificationService.verifyStatus(response, false);
        this.loaderService.stop();
        if(success) resolve(response.data);
        resolve(null);
      },
      (error: any) => {
        this.notificationService.error(error);
        this.loaderService.stop();
        reject(error);
      });
    });
  }

  getDataById(Id: number) 
  {
    this.loaderService.start();
    return new Promise((resolve, reject) => {
      this.http.get(apiRoutes.ROUTE_USUARIO + '/' + Id).subscribe(
      (response: any) => {
        let success = this.notificationService.verifyStatus(response, false);
        this.loaderService.stop();
        if(success) resolve(response.data);
        resolve(null);
      },
      (error: any) => {
        this.notificationService.error(error);
        this.loaderService.stop();
        reject(error);
      });
    });
  }

  sendData(objUsuario: IUserData) 
  {
    this.loaderService.start();
    let method = objUsuario.UsuarioId ? 'put' : 'post';
    return new Promise((resolve, reject) => {
      this.http[method](apiRoutes.ROUTE_USUARIO, objUsuario).subscribe(
      (response: any) => {
        let success = this.notificationService.verifyStatus(response, true);
        this.loaderService.stop();
        resolve({ success: success, usuarioId: response.data && response.data.length > 0 ? response.data[0].UsuarioId : null });
      },
      (error: any) => {
        this.notificationService.error(error);
        this.loaderService.stop();
        reject(error);
      });
    });
  }

  deleteData(usuarioId: number) 
  {
    this.loaderService.start();
    return new Promise((resolve, reject) => {
      this.http.delete(apiRoutes.ROUTE_USUARIO + '/' + usuarioId).subscribe(
      (response: any) => {
        let success = this.notificationService.verifyStatus(response, true);
        this.loaderService.stop();
        resolve(success);
      },
      (error: any) => {
        this.notificationService.error(error);
        this.loaderService.stop();
        reject(error);
      });
    });
  }
  
  resetPassword(usuarioId: number) 
  {
    this.loaderService.start();
    return new Promise((resolve, reject) => {
      this.http.post(apiRoutes.ROUTE_RENICIO_PASS, {"UsuarioId": usuarioId},).subscribe(
      (response: any) => {
        console.log(response);
        let success = this.notificationService.verifyStatus(response, true);
        this.loaderService.stop();
        resolve({success: success, password: response.data.Password});
      },
      (error: any) => {
        this.notificationService.error(error);
        this.loaderService.stop();
        reject(error);
      });
    });
  }
  
  changePassword(objPassword: IPasswordData) 
  {
    this.loaderService.start();
    return new Promise((resolve, reject) => {
      this.http.put(apiRoutes.ROUTE_CAMBIAR_PASS, objPassword,).subscribe(
      (response: any) => {
        console.log(response);
        let success = this.notificationService.verifyStatus(response, true);
        this.loaderService.stop();
        resolve(success);
      },
      (error: any) => {
        this.notificationService.error(error);
        this.loaderService.stop();
        reject(error);
      });
    });
  }
}