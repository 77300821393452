import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { apiRoutes } from '../../constants/constants';
import { NotificationService } from '../notification.service';

@Injectable({ providedIn: 'root' })
export class DashboardService
{
  private fechaInicio: string = null;
  private fechaFin: string = null;

  constructor(private http: HttpClient, private notificationService: NotificationService, private loaderService: NgxUiLoaderService) {
  }

  getData()
  {
    this.loaderService.start();
    if(!this.fechaInicio) this.fechaInicio = this.createStringDate(new Date(), true);
    if(!this.fechaFin) this.fechaFin = this.createStringDate(new Date());
    return new Promise((resolve, reject) => {
      this.http.get(apiRoutes.ROUTE_DASHBOARD + `?fInicio=${ this.fechaInicio }&fFin=${ this.fechaFin }`).subscribe(
      (response: any) => {
        let success = this.notificationService.verifyStatus(response, false);
        this.loaderService.stop();
        if(success) resolve(response.data);
        resolve(null);
      },
      (error: any) => {
        this.notificationService.error(error);
        this.loaderService.stop();
        reject(error);
      });
    });
  }

  createStringDate(date: Date, firstDay?: boolean)
  {
      if(firstDay) date.setDate(1);
      let strDate = date.toISOString();
      return strDate.split('T')[0];
  }

  setMonth(objMonth: any)
  {
      let dateInicio = new Date(objMonth.year, objMonth.month, 1);
      let dateFin = new Date(objMonth.year, objMonth.month + 1, 0);
      this.fechaInicio = dateInicio.toISOString().split('T')[0];
      this.fechaFin = dateFin.toISOString().split('T')[0];
  }

  setDay(date: Date)
  {
      let strDate = date.toISOString().split('T')[0];
      this.fechaInicio = strDate;
      this.fechaFin = strDate;
  }

  setRange(startDate: Date, finishDate: Date)
  {
      this.fechaInicio = startDate.toISOString().split('T')[0];
      this.fechaFin = finishDate.toISOString().split('T')[0];
  }
  
}